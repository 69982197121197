(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
(function (global){
/**
 * @license
 * Lodash (Custom Build) lodash.com/license | Underscore.js 1.8.3 underscorejs.org/LICENSE
 * Build: `lodash include="cloneDeep,cloneDeepWith,get,has,isEmpty,isEqual,isNull,isPlainObject,isObject,merge,mergeWith,omit,reject" -p -o custom-lodash.js`
 */
;(function(){function t(t,e,n){switch(n.length){case 0:return t.call(e);case 1:return t.call(e,n[0]);case 2:return t.call(e,n[0],n[1]);case 3:return t.call(e,n[0],n[1],n[2])}return t.apply(e,n)}function e(t,e){for(var n=-1,r=null==t?0:t.length;++n<r&&false!==e(t[n],n,t););}function n(t,e){for(var n=-1,r=null==t?0:t.length,o=0,c=[];++n<r;){var u=t[n];e(u,n,t)&&(c[o++]=u)}return c}function r(t,e){for(var n=-1,r=null==t?0:t.length,o=Array(r);++n<r;)o[n]=e(t[n],n,t);return o}function o(t,e){for(var n=-1,r=e.length,o=t.length;++n<r;)t[o+n]=e[n];
return t}function c(t,e){for(var n=-1,r=null==t?0:t.length;++n<r;)if(e(t[n],n,t))return true;return false}function u(t){return function(e){return null==e?Jt:e[t]}}function i(t){return function(e){return t(e)}}function a(t){var e=-1,n=Array(t.size);return t.forEach(function(t,r){n[++e]=[r,t]}),n}function f(t){var e=Object;return function(n){return t(e(n))}}function l(t){var e=-1,n=Array(t.size);return t.forEach(function(t){n[++e]=t}),n}function s(){}function b(t){var e=-1,n=null==t?0:t.length;for(this.clear();++e<n;){
var r=t[e];this.set(r[0],r[1])}}function h(t){var e=-1,n=null==t?0:t.length;for(this.clear();++e<n;){var r=t[e];this.set(r[0],r[1])}}function p(t){var e=-1,n=null==t?0:t.length;for(this.clear();++e<n;){var r=t[e];this.set(r[0],r[1])}}function y(t){var e=-1,n=null==t?0:t.length;for(this.__data__=new p;++e<n;)this.add(t[e])}function j(t){this.size=(this.__data__=new h(t)).size}function _(t,e){var n=bn(t),r=!n&&sn(t),o=!n&&!r&&hn(t),c=!n&&!r&&!o&&jn(t);if(n=n||r||o||c){for(var r=t.length,u=String,i=-1,a=Array(r);++i<r;)a[i]=u(i);
r=a}else r=[];var f,u=r.length;for(f in t)!e&&!de.call(t,f)||n&&("length"==f||o&&("offset"==f||"parent"==f)||c&&("buffer"==f||"byteLength"==f||"byteOffset"==f)||jt(f,u))||r.push(f);return r}function g(t,e,n){(n===Jt||kt(t[e],n))&&(n!==Jt||e in t)||m(t,e,n)}function v(t,e,n){var r=t[e];de.call(t,e)&&kt(r,n)&&(n!==Jt||e in t)||m(t,e,n)}function d(t,e){for(var n=t.length;n--;)if(kt(t[n][0],e))return n;return-1}function A(t,e){return t&&Y(e,Tt(e),t)}function w(t,e){return t&&Y(e,Vt(e),t)}function m(t,e,n){
"__proto__"==e&&Be?Be(t,e,{configurable:true,enumerable:true,value:n,writable:true}):t[e]=n}function O(t,n,r,o,c,u){var i,a=1&n,f=2&n,l=4&n;if(r&&(i=c?r(t,o,c,u):r(t)),i!==Jt)return i;if(!It(t))return t;if(o=bn(t)){if(i=bt(t),!a)return X(t,i)}else{var s=an(t),b="[object Function]"==s||"[object GeneratorFunction]"==s;if(hn(t))return J(t,a);if("[object Object]"==s||"[object Arguments]"==s||b&&!c){if(i=f||b?{}:ht(t),!a)return f?tt(t,w(i,t)):Z(t,A(i,t))}else{if(!oe[s])return c?t:{};i=pt(t,s,a)}}if(u||(u=new j),
c=u.get(t))return c;if(u.set(t,i),yn(t))return t.forEach(function(e){i.add(O(e,n,r,e,t,u))}),i;if(pn(t))return t.forEach(function(e,o){i.set(o,O(e,n,r,o,t,u))}),i;var f=l?f?ut:ct:f?Vt:Tt,h=o?Jt:f(t);return e(h||t,function(e,o){h&&(o=e,e=t[o]),v(i,o,O(e,n,r,o,t,u))}),i}function S(t,e){var n=[];return nn(t,function(t,r,o){e(t,r,o)&&n.push(t)}),n}function z(t,e,n,r,c){var u=-1,i=t.length;for(n||(n=yt),c||(c=[]);++u<i;){var a=t[u];0<e&&n(a)?1<e?z(a,e-1,n,r,c):o(c,a):r||(c[c.length]=a)}return c}function k(t,e){
e=H(e,t);for(var n=0,r=e.length;null!=t&&n<r;)t=t[At(e[n++])];return n&&n==r?t:Jt}function x(t,e,n){return e=e(t),bn(t)?e:o(e,n(t))}function E(t){if(null==t)t=t===Jt?"[object Undefined]":"[object Null]";else if(Ue&&Ue in Object(t)){var e=de.call(t,Ue),n=t[Ue];try{t[Ue]=Jt;var r=true}catch(t){}var o=we.call(t);r&&(e?t[Ue]=n:delete t[Ue]),t=o}else t=we.call(t);return t}function F(t,e){return null!=t&&de.call(t,e)}function M(t,e){return null!=t&&e in Object(t)}function I(t){return Dt(t)&&"[object Arguments]"==E(t);
}function D(t,e,n,r,o){if(t===e)e=true;else if(null==t||null==e||!Dt(t)&&!Dt(e))e=t!==t&&e!==e;else t:{var c=bn(t),u=bn(e),i=c?"[object Array]":an(t),a=u?"[object Array]":an(e),i="[object Arguments]"==i?"[object Object]":i,a="[object Arguments]"==a?"[object Object]":a,f="[object Object]"==i,u="[object Object]"==a;if((a=i==a)&&hn(t)){if(!hn(e)){e=false;break t}c=true,f=false}if(a&&!f)o||(o=new j),e=c||jn(t)?rt(t,e,n,r,D,o):ot(t,e,i,n,r,D,o);else{if(!(1&n)&&(c=f&&de.call(t,"__wrapped__"),i=u&&de.call(e,"__wrapped__"),
c||i)){t=c?t.value():t,e=i?e.value():e,o||(o=new j),e=D(t,e,n,r,o);break t}if(a)e:if(o||(o=new j),c=1&n,i=ct(t),u=i.length,a=ct(e).length,u==a||c){for(f=u;f--;){var l=i[f];if(!(c?l in e:de.call(e,l))){e=false;break e}}if((a=o.get(t))&&o.get(e))e=a==e;else{a=true,o.set(t,e),o.set(e,t);for(var s=c;++f<u;){var l=i[f],b=t[l],h=e[l];if(r)var p=c?r(h,b,l,e,t,o):r(b,h,l,t,e,o);if(p===Jt?b!==h&&!D(b,h,n,r,o):!p){a=false;break}s||(s="constructor"==l)}a&&!s&&(n=t.constructor,r=e.constructor,n!=r&&"constructor"in t&&"constructor"in e&&!(typeof n=="function"&&n instanceof n&&typeof r=="function"&&r instanceof r)&&(a=false)),
o.delete(t),o.delete(e),e=a}}else e=false;else e=false}}return e}function U(t){return Dt(t)&&"[object Map]"==an(t)}function B(t,e){var n=e.length,r=n;if(null==t)return!r;for(t=Object(t);n--;){var o=e[n];if(o[2]?o[1]!==t[o[0]]:!(o[0]in t))return false}for(;++n<r;){var o=e[n],c=o[0],u=t[c],i=o[1];if(o[2]){if(u===Jt&&!(c in t))return false}else if(o=new j,void 0===Jt?!D(i,u,3,void 0,o):1)return false}return true}function P(t){return Dt(t)&&"[object Set]"==an(t)}function L(t){return Dt(t)&&Mt(t.length)&&!!re[E(t)]}function $(t){
return typeof t=="function"?t:null==t?Rt:typeof t=="object"?bn(t)?V(t[0],t[1]):T(t):qt(t)}function C(t){if(!gt(t))return $e(t);var e,n=[];for(e in Object(t))de.call(t,e)&&"constructor"!=e&&n.push(e);return n}function T(t){var e=ft(t);return 1==e.length&&e[0][2]?vt(e[0][0],e[0][1]):function(n){return n===t||B(n,e)}}function V(t,e){return _t(t)&&e===e&&!It(e)?vt(At(t),e):function(n){var r=$t(n,t);return r===Jt&&r===e?Ct(n,t):D(e,r,3)}}function N(t,e,n,r,o){t!==e&&rn(e,function(c,u){if(It(c)){o||(o=new j);
var i=o,a="__proto__"==u?Jt:t[u],f="__proto__"==u?Jt:e[u],l=i.get(f);if(l)g(t,u,l);else{var l=r?r(a,f,u+"",t,e,i):Jt,s=l===Jt;if(s){var b=bn(f),h=!b&&hn(f),p=!b&&!h&&jn(f),l=f;b||h||p?bn(a)?l=a:Et(a)?l=X(a):h?(s=false,l=J(f,true)):p?(s=false,l=Q(f,true)):l=[]:Ut(f)||sn(f)?(l=a,sn(a)?l=Pt(a):(!It(a)||n&&Ft(a))&&(l=ht(f))):s=false}s&&(i.set(f,l),N(l,f,n,r,i),i.delete(f)),g(t,u,l)}}else i=r?r("__proto__"==u?Jt:t[u],c,u+"",t,e,o):Jt,i===Jt&&(i=c),g(t,u,i)},Vt)}function R(t){return function(e){return k(e,t)}}function W(t){
return fn(dt(t,void 0,Rt),t+"")}function q(t){if(typeof t=="string")return t;if(bn(t))return r(t,q)+"";if(Bt(t))return tn?tn.call(t):"";var e=t+"";return"0"==e&&1/t==-Kt?"-0":e}function G(t,e){e=H(e,t);var n;if(2>e.length)n=t;else{n=e;var r=0,o=-1,c=-1,u=n.length;for(0>r&&(r=-r>u?0:u+r),o=o>u?u:o,0>o&&(o+=u),u=r>o?0:o-r>>>0,r>>>=0,o=Array(u);++c<u;)o[c]=n[c+r];n=k(t,o)}t=n,null==t||delete t[At(Ot(e))]}function H(t,e){return bn(t)?t:_t(t,e)?[t]:ln(Lt(t))}function J(t,e){if(e)return t.slice();var n=t.length,n=xe?xe(n):new t.constructor(n);
return t.copy(n),n}function K(t){var e=new t.constructor(t.byteLength);return new ke(e).set(new ke(t)),e}function Q(t,e){return new t.constructor(e?K(t.buffer):t.buffer,t.byteOffset,t.length)}function X(t,e){var n=-1,r=t.length;for(e||(e=Array(r));++n<r;)e[n]=t[n];return e}function Y(t,e,n){var r=!n;n||(n={});for(var o=-1,c=e.length;++o<c;){var u=e[o],i=Jt;i===Jt&&(i=t[u]),r?m(n,u,i):v(n,u,i)}return n}function Z(t,e){return Y(t,cn(t),e)}function tt(t,e){return Y(t,un(t),e)}function et(t){return W(function(e,n){
var r,o=-1,c=n.length,u=1<c?n[c-1]:Jt,i=2<c?n[2]:Jt,u=3<t.length&&typeof u=="function"?(c--,u):Jt;if(r=i){r=n[0];var a=n[1];if(It(i)){var f=typeof a;r=!!("number"==f?xt(i)&&jt(a,i.length):"string"==f&&a in i)&&kt(i[a],r)}else r=false}for(r&&(u=3>c?Jt:u,c=1),e=Object(e);++o<c;)(i=n[o])&&t(e,i,o,u);return e})}function nt(t){return Ut(t)?Jt:t}function rt(t,e,n,r,o,u){var i=1&n,a=t.length,f=e.length;if(a!=f&&!(i&&f>a))return false;if((f=u.get(t))&&u.get(e))return f==e;var f=-1,l=true,s=2&n?new y:Jt;for(u.set(t,e),
u.set(e,t);++f<a;){var b=t[f],h=e[f];if(r)var p=i?r(h,b,f,e,t,u):r(b,h,f,t,e,u);if(p!==Jt){if(p)continue;l=false;break}if(s){if(!c(e,function(t,e){if(!s.has(e)&&(b===t||o(b,t,n,r,u)))return s.push(e)})){l=false;break}}else if(b!==h&&!o(b,h,n,r,u)){l=false;break}}return u.delete(t),u.delete(e),l}function ot(t,e,n,r,o,c,u){switch(n){case"[object DataView]":if(t.byteLength!=e.byteLength||t.byteOffset!=e.byteOffset)break;t=t.buffer,e=e.buffer;case"[object ArrayBuffer]":if(t.byteLength!=e.byteLength||!c(new ke(t),new ke(e)))break;
return true;case"[object Boolean]":case"[object Date]":case"[object Number]":return kt(+t,+e);case"[object Error]":return t.name==e.name&&t.message==e.message;case"[object RegExp]":case"[object String]":return t==e+"";case"[object Map]":var i=a;case"[object Set]":if(i||(i=l),t.size!=e.size&&!(1&r))break;return(n=u.get(t))?n==e:(r|=2,u.set(t,e),e=rt(i(t),i(e),r,o,c,u),u.delete(t),e);case"[object Symbol]":if(Ze)return Ze.call(t)==Ze.call(e)}return false}function ct(t){return x(t,Tt,cn)}function ut(t){return x(t,Vt,un);
}function it(){var t=s.iteratee||Wt,t=t===Wt?$:t;return arguments.length?t(arguments[0],arguments[1]):t}function at(t,e){var n=t.__data__,r=typeof e;return("string"==r||"number"==r||"symbol"==r||"boolean"==r?"__proto__"!==e:null===e)?n[typeof e=="string"?"string":"hash"]:n.map}function ft(t){for(var e=Tt(t),n=e.length;n--;){var r=e[n],o=t[r];e[n]=[r,o,o===o&&!It(o)]}return e}function lt(t,e){var n=null==t?Jt:t[e];return(!It(n)||Ae&&Ae in n?0:(Ft(n)?Oe:ee).test(wt(n)))?n:Jt}function st(t,e,n){e=H(e,t);
for(var r=-1,o=e.length,c=false;++r<o;){var u=At(e[r]);if(!(c=null!=t&&n(t,u)))break;t=t[u]}return c||++r!=o?c:(o=null==t?0:t.length,!!o&&Mt(o)&&jt(u,o)&&(bn(t)||sn(t)))}function bt(t){var e=t.length,n=new t.constructor(e);return e&&"string"==typeof t[0]&&de.call(t,"index")&&(n.index=t.index,n.input=t.input),n}function ht(t){return typeof t.constructor!="function"||gt(t)?{}:en(Ee(t))}function pt(t,e,n){var r=t.constructor;switch(e){case"[object ArrayBuffer]":return K(t);case"[object Boolean]":case"[object Date]":
return new r(+t);case"[object DataView]":return e=n?K(t.buffer):t.buffer,new t.constructor(e,t.byteOffset,t.byteLength);case"[object Float32Array]":case"[object Float64Array]":case"[object Int8Array]":case"[object Int16Array]":case"[object Int32Array]":case"[object Uint8Array]":case"[object Uint8ClampedArray]":case"[object Uint16Array]":case"[object Uint32Array]":return Q(t,n);case"[object Map]":return new r;case"[object Number]":case"[object String]":return new r(t);case"[object RegExp]":return e=new t.constructor(t.source,te.exec(t)),
e.lastIndex=t.lastIndex,e;case"[object Set]":return new r;case"[object Symbol]":return Ze?Object(Ze.call(t)):{}}}function yt(t){return bn(t)||sn(t)||!!(De&&t&&t[De])}function jt(t,e){var n=typeof t;return e=null==e?9007199254740991:e,!!e&&("number"==n||"symbol"!=n&&ne.test(t))&&-1<t&&0==t%1&&t<e}function _t(t,e){if(bn(t))return false;var n=typeof t;return!("number"!=n&&"symbol"!=n&&"boolean"!=n&&null!=t&&!Bt(t))||(Xt.test(t)||!Qt.test(t)||null!=e&&t in Object(e))}function gt(t){var e=t&&t.constructor;
return t===(typeof e=="function"&&e.prototype||_e)}function vt(t,e){return function(n){return null!=n&&(n[t]===e&&(e!==Jt||t in Object(n)))}}function dt(e,n,r){return n=Ce(n===Jt?e.length-1:n,0),function(){for(var o=arguments,c=-1,u=Ce(o.length-n,0),i=Array(u);++c<u;)i[c]=o[n+c];for(c=-1,u=Array(n+1);++c<n;)u[c]=o[c];return u[n]=r(i),t(e,this,u)}}function At(t){if(typeof t=="string"||Bt(t))return t;var e=t+"";return"0"==e&&1/t==-Kt?"-0":e}function wt(t){if(null!=t){try{return ve.call(t)}catch(t){}
return t+""}return""}function mt(t){return(null==t?0:t.length)?z(t,1):[]}function Ot(t){var e=null==t?0:t.length;return e?t[e-1]:Jt}function St(t,e){function n(){var r=arguments,o=e?e.apply(this,r):r[0],c=n.cache;return c.has(o)?c.get(o):(r=t.apply(this,r),n.cache=c.set(o,r)||c,r)}if(typeof t!="function"||null!=e&&typeof e!="function")throw new TypeError("Expected a function");return n.cache=new(St.Cache||p),n}function zt(t){if(typeof t!="function")throw new TypeError("Expected a function");return function(){
var e=arguments;switch(e.length){case 0:return!t.call(this);case 1:return!t.call(this,e[0]);case 2:return!t.call(this,e[0],e[1]);case 3:return!t.call(this,e[0],e[1],e[2])}return!t.apply(this,e)}}function kt(t,e){return t===e||t!==t&&e!==e}function xt(t){return null!=t&&Mt(t.length)&&!Ft(t)}function Et(t){return Dt(t)&&xt(t)}function Ft(t){return!!It(t)&&(t=E(t),"[object Function]"==t||"[object GeneratorFunction]"==t||"[object AsyncFunction]"==t||"[object Proxy]"==t)}function Mt(t){return typeof t=="number"&&-1<t&&0==t%1&&9007199254740991>=t;
}function It(t){var e=typeof t;return null!=t&&("object"==e||"function"==e)}function Dt(t){return null!=t&&typeof t=="object"}function Ut(t){return!(!Dt(t)||"[object Object]"!=E(t))&&(t=Ee(t),null===t||(t=de.call(t,"constructor")&&t.constructor,typeof t=="function"&&t instanceof t&&ve.call(t)==me))}function Bt(t){return typeof t=="symbol"||Dt(t)&&"[object Symbol]"==E(t)}function Pt(t){return Y(t,Vt(t))}function Lt(t){return null==t?"":q(t)}function $t(t,e,n){return t=null==t?Jt:k(t,e),t===Jt?n:t}function Ct(t,e){
return null!=t&&st(t,e,M)}function Tt(t){return xt(t)?_(t):C(t)}function Vt(t){if(xt(t))t=_(t,true);else if(It(t)){var e,n=gt(t),r=[];for(e in t)("constructor"!=e||!n&&de.call(t,e))&&r.push(e);t=r}else{if(e=[],null!=t)for(n in Object(t))e.push(n);t=e}return t}function Nt(t){return function(){return t}}function Rt(t){return t}function Wt(t){return $(typeof t=="function"?t:O(t,1))}function qt(t){return _t(t)?u(At(t)):R(t)}function Gt(){return[]}function Ht(){return false}var Jt,Kt=1/0,Qt=/\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/,Xt=/^\w*$/,Yt=/[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g,Zt=/\\(\\)?/g,te=/\w*$/,ee=/^\[object .+?Constructor\]$/,ne=/^(?:0|[1-9]\d*)$/,re={};
re["[object Float32Array]"]=re["[object Float64Array]"]=re["[object Int8Array]"]=re["[object Int16Array]"]=re["[object Int32Array]"]=re["[object Uint8Array]"]=re["[object Uint8ClampedArray]"]=re["[object Uint16Array]"]=re["[object Uint32Array]"]=true,re["[object Arguments]"]=re["[object Array]"]=re["[object ArrayBuffer]"]=re["[object Boolean]"]=re["[object DataView]"]=re["[object Date]"]=re["[object Error]"]=re["[object Function]"]=re["[object Map]"]=re["[object Number]"]=re["[object Object]"]=re["[object RegExp]"]=re["[object Set]"]=re["[object String]"]=re["[object WeakMap]"]=false;
var oe={};oe["[object Arguments]"]=oe["[object Array]"]=oe["[object ArrayBuffer]"]=oe["[object DataView]"]=oe["[object Boolean]"]=oe["[object Date]"]=oe["[object Float32Array]"]=oe["[object Float64Array]"]=oe["[object Int8Array]"]=oe["[object Int16Array]"]=oe["[object Int32Array]"]=oe["[object Map]"]=oe["[object Number]"]=oe["[object Object]"]=oe["[object RegExp]"]=oe["[object Set]"]=oe["[object String]"]=oe["[object Symbol]"]=oe["[object Uint8Array]"]=oe["[object Uint8ClampedArray]"]=oe["[object Uint16Array]"]=oe["[object Uint32Array]"]=true,
oe["[object Error]"]=oe["[object Function]"]=oe["[object WeakMap]"]=false;var ce,ue=typeof global=="object"&&global&&global.Object===Object&&global,ie=typeof self=="object"&&self&&self.Object===Object&&self,ae=ue||ie||Function("return this")(),fe=typeof exports=="object"&&exports&&!exports.nodeType&&exports,le=fe&&typeof module=="object"&&module&&!module.nodeType&&module,se=le&&le.exports===fe,be=se&&ue.process;t:{try{ce=be&&be.binding&&be.binding("util");break t}catch(t){}ce=void 0}var he=ce&&ce.isMap,pe=ce&&ce.isSet,ye=ce&&ce.isTypedArray,je=Array.prototype,_e=Object.prototype,ge=ae["__core-js_shared__"],ve=Function.prototype.toString,de=_e.hasOwnProperty,Ae=function(){
var t=/[^.]+$/.exec(ge&&ge.keys&&ge.keys.IE_PROTO||"");return t?"Symbol(src)_1."+t:""}(),we=_e.toString,me=ve.call(Object),Oe=RegExp("^"+ve.call(de).replace(/[\\^$.*+?()[\]{}|]/g,"\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g,"$1.*?")+"$"),Se=se?ae.Buffer:Jt,ze=ae.Symbol,ke=ae.Uint8Array,xe=Se?Se.a:Jt,Ee=f(Object.getPrototypeOf),Fe=Object.create,Me=_e.propertyIsEnumerable,Ie=je.splice,De=ze?ze.isConcatSpreadable:Jt,Ue=ze?ze.toStringTag:Jt,Be=function(){try{var t=lt(Object,"defineProperty");
return t({},"",{}),t}catch(t){}}(),Pe=Object.getOwnPropertySymbols,Le=Se?Se.isBuffer:Jt,$e=f(Object.keys),Ce=Math.max,Te=Date.now,Ve=lt(ae,"DataView"),Ne=lt(ae,"Map"),Re=lt(ae,"Promise"),We=lt(ae,"Set"),qe=lt(ae,"WeakMap"),Ge=lt(Object,"create"),He=wt(Ve),Je=wt(Ne),Ke=wt(Re),Qe=wt(We),Xe=wt(qe),Ye=ze?ze.prototype:Jt,Ze=Ye?Ye.valueOf:Jt,tn=Ye?Ye.toString:Jt,en=function(){function t(){}return function(e){return It(e)?Fe?Fe(e):(t.prototype=e,e=new t,t.prototype=Jt,e):{}}}();b.prototype.clear=function(){
this.__data__=Ge?Ge(null):{},this.size=0},b.prototype.delete=function(t){return t=this.has(t)&&delete this.__data__[t],this.size-=t?1:0,t},b.prototype.get=function(t){var e=this.__data__;return Ge?(t=e[t],"__lodash_hash_undefined__"===t?Jt:t):de.call(e,t)?e[t]:Jt},b.prototype.has=function(t){var e=this.__data__;return Ge?e[t]!==Jt:de.call(e,t)},b.prototype.set=function(t,e){var n=this.__data__;return this.size+=this.has(t)?0:1,n[t]=Ge&&e===Jt?"__lodash_hash_undefined__":e,this},h.prototype.clear=function(){
this.__data__=[],this.size=0},h.prototype.delete=function(t){var e=this.__data__;return t=d(e,t),!(0>t)&&(t==e.length-1?e.pop():Ie.call(e,t,1),--this.size,true)},h.prototype.get=function(t){var e=this.__data__;return t=d(e,t),0>t?Jt:e[t][1]},h.prototype.has=function(t){return-1<d(this.__data__,t)},h.prototype.set=function(t,e){var n=this.__data__,r=d(n,t);return 0>r?(++this.size,n.push([t,e])):n[r][1]=e,this},p.prototype.clear=function(){this.size=0,this.__data__={hash:new b,map:new(Ne||h),string:new b
}},p.prototype.delete=function(t){return t=at(this,t).delete(t),this.size-=t?1:0,t},p.prototype.get=function(t){return at(this,t).get(t)},p.prototype.has=function(t){return at(this,t).has(t)},p.prototype.set=function(t,e){var n=at(this,t),r=n.size;return n.set(t,e),this.size+=n.size==r?0:1,this},y.prototype.add=y.prototype.push=function(t){return this.__data__.set(t,"__lodash_hash_undefined__"),this},y.prototype.has=function(t){return this.__data__.has(t)},j.prototype.clear=function(){this.__data__=new h,
this.size=0},j.prototype.delete=function(t){var e=this.__data__;return t=e.delete(t),this.size=e.size,t},j.prototype.get=function(t){return this.__data__.get(t)},j.prototype.has=function(t){return this.__data__.has(t)},j.prototype.set=function(t,e){var n=this.__data__;if(n instanceof h){var r=n.__data__;if(!Ne||199>r.length)return r.push([t,e]),this.size=++n.size,this;n=this.__data__=new p(r)}return n.set(t,e),this.size=n.size,this};var nn=function(t,e){return function(n,r){if(null==n)return n;if(!xt(n))return t(n,r);
for(var o=n.length,c=e?o:-1,u=Object(n);(e?c--:++c<o)&&false!==r(u[c],c,u););return n}}(function(t,e){return t&&rn(t,e,Tt)}),rn=function(t){return function(e,n,r){var o=-1,c=Object(e);r=r(e);for(var u=r.length;u--;){var i=r[t?u:++o];if(false===n(c[i],i,c))break}return e}}(),on=Be?function(t,e){return Be(t,"toString",{configurable:true,enumerable:false,value:Nt(e),writable:true})}:Rt,cn=Pe?function(t){return null==t?[]:(t=Object(t),n(Pe(t),function(e){return Me.call(t,e)}))}:Gt,un=Pe?function(t){for(var e=[];t;)o(e,cn(t)),
t=Ee(t);return e}:Gt,an=E;(Ve&&"[object DataView]"!=an(new Ve(new ArrayBuffer(1)))||Ne&&"[object Map]"!=an(new Ne)||Re&&"[object Promise]"!=an(Re.resolve())||We&&"[object Set]"!=an(new We)||qe&&"[object WeakMap]"!=an(new qe))&&(an=function(t){var e=E(t);if(t=(t="[object Object]"==e?t.constructor:Jt)?wt(t):"")switch(t){case He:return"[object DataView]";case Je:return"[object Map]";case Ke:return"[object Promise]";case Qe:return"[object Set]";case Xe:return"[object WeakMap]"}return e});var fn=function(t){
var e=0,n=0;return function(){var r=Te(),o=16-(r-n);if(n=r,0<o){if(800<=++e)return arguments[0]}else e=0;return t.apply(Jt,arguments)}}(on),ln=function(t){t=St(t,function(t){return 500===e.size&&e.clear(),t});var e=t.cache;return t}(function(t){var e=[];return 46===t.charCodeAt(0)&&e.push(""),t.replace(Yt,function(t,n,r,o){e.push(r?o.replace(Zt,"$1"):n||t)}),e});St.Cache=p;var sn=I(function(){return arguments}())?I:function(t){return Dt(t)&&de.call(t,"callee")&&!Me.call(t,"callee")},bn=Array.isArray,hn=Le||Ht,pn=he?i(he):U,yn=pe?i(pe):P,jn=ye?i(ye):L,_n=et(function(t,e,n){
N(t,e,n)}),gn=et(function(t,e,n,r){N(t,e,n,r)}),vn=function(t){return fn(dt(t,Jt,mt),t+"")}(function(t,e){var n={};if(null==t)return n;var o=false;e=r(e,function(e){return e=H(e,t),o||(o=1<e.length),e}),Y(t,ut(t),n),o&&(n=O(n,7,nt));for(var c=e.length;c--;)G(n,e[c]);return n});s.constant=Nt,s.flatten=mt,s.iteratee=Wt,s.keys=Tt,s.keysIn=Vt,s.memoize=St,s.merge=_n,s.mergeWith=gn,s.negate=zt,s.omit=vn,s.property=qt,s.reject=function(t,e){return(bn(t)?n:S)(t,zt(it(e,3)))},s.toPlainObject=Pt,s.cloneDeep=function(t){
return O(t,5)},s.cloneDeepWith=function(t,e){return e=typeof e=="function"?e:Jt,O(t,5,e)},s.eq=kt,s.get=$t,s.has=function(t,e){return null!=t&&st(t,e,F)},s.hasIn=Ct,s.identity=Rt,s.isArguments=sn,s.isArray=bn,s.isArrayLike=xt,s.isArrayLikeObject=Et,s.isBuffer=hn,s.isEmpty=function(t){if(null==t)return true;if(xt(t)&&(bn(t)||typeof t=="string"||typeof t.splice=="function"||hn(t)||jn(t)||sn(t)))return!t.length;var e=an(t);if("[object Map]"==e||"[object Set]"==e)return!t.size;if(gt(t))return!C(t).length;
for(var n in t)if(de.call(t,n))return false;return true},s.isEqual=function(t,e){return D(t,e)},s.isFunction=Ft,s.isLength=Mt,s.isMap=pn,s.isNull=function(t){return null===t},s.isObject=It,s.isObjectLike=Dt,s.isPlainObject=Ut,s.isSet=yn,s.isSymbol=Bt,s.isTypedArray=jn,s.last=Ot,s.stubArray=Gt,s.stubFalse=Ht,s.toString=Lt,s.VERSION="4.17.5",typeof define=="function"&&typeof define.amd=="object"&&define.amd?(ae._=s, define(function(){return s})):le?((le.exports=s)._=s,fe._=s):ae._=s}).call(this);
}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{}],2:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/
const CONSTANTS = {
  /**
   * @typedef {String} ItemType
   **/

  /**
   * Enumeration of data layer item types.
   *
   * @enum {ItemType}
   * @readonly
   */
  itemType: {
    /** Represents an item of type data */
    DATA: 'data',
    /** Represents an item of type function */
    FCTN: 'fctn',
    /** Represents an item of type event */
    EVENT: 'event',
    /** Represents an item of type listener on */
    LISTENER_ON: 'listenerOn',
    /** Represents an item of type listener off */
    LISTENER_OFF: 'listenerOff'
  },

  /**
   * @typedef {String} DataLayerEvent
   **/

  /**
   * Enumeration of data layer events.
   *
   * @enum {DataLayerEvent}
   * @readonly
   */
  dataLayerEvent: {
    /** Represents an event triggered for any change in the data layer state */
    CHANGE: 'adobeDataLayer:change',
    /** Represents an event triggered for any event push to the data layer */
    EVENT: 'adobeDataLayer:event'
  },

  /**
   * @typedef {String} ListenerScope
   **/

  /**
   * Enumeration of listener scopes.
   *
   * @enum {ListenerScope}
   * @readonly
   */
  listenerScope: {
    /** Past events only */
    PAST: 'past',
    /** Future events only */
    FUTURE: 'future',
    /** All events, past and future */
    ALL: 'all'
  }
};

module.exports = CONSTANTS;

},{}],3:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = require('../custom-lodash');
const version = require('../version.json').version;
const cloneDeep = _.cloneDeep;
const get = _.get;

const Item = require('./item');
const Listener = require('./listener');
const ListenerManager = require('./listenerManager');
const CONSTANTS = require('./constants');
const customMerge = require('./utils/customMerge');

/**
 * Manager
 *
 * @class Manager
 * @classdesc Data Layer manager that augments the passed data layer array and handles eventing.
 * @param {Object} config The Data Layer manager configuration.
 */
module.exports = function(config) {
  const _config = config || {};
  let _dataLayer = [];
  let _preLoadedItems = [];
  let _state = {};
  let _previousStateCopy = {};
  let _listenerManager;

  const DataLayerManager = {
    getState: function() {
      return _state;
    },
    getDataLayer: function() {
      return _dataLayer;
    },
    getPreviousState: function() {
      return _previousStateCopy;
    }
  };

  _initialize();
  _augment();
  _processItems();

  /**
   * Initializes the data layer.
   *
   * @private
   */
  function _initialize() {
    if (!Array.isArray(_config.dataLayer)) {
      _config.dataLayer = [];
    }

    // Remove preloaded items from the data layer array and add those to the array of preloaded items
    _preLoadedItems = _config.dataLayer.splice(0, _config.dataLayer.length);
    _dataLayer = _config.dataLayer;
    _dataLayer.version = version;
    _state = {};
    _previousStateCopy = {};
    _listenerManager = ListenerManager(DataLayerManager);
  };

  /**
   * Updates the state with the item.
   *
   * @param {Item} item The item.
   * @private
   */
  function _updateState(item) {
    _previousStateCopy = cloneDeep(_state);
    _state = customMerge(_state, item.data);
  };

  /**
   * Augments the data layer Array Object, overriding: push() and adding getState(), addEventListener and removeEventListener.
   *
   * @private
   */
  function _augment() {
    /**
     * Pushes one or more items to the data layer.
     *
     * @param {...ItemConfig} var_args The items to add to the data layer.
     * @returns {Number} The length of the data layer following push.
     */
    _dataLayer.push = function(var_args) { /* eslint-disable-line camelcase */
      const pushArguments = arguments;
      const filteredArguments = arguments;

      Object.keys(pushArguments).forEach(function(key) {
        const itemConfig = pushArguments[key];
        const item = Item(itemConfig);

        if (!item.valid) {
          _logInvalidItemError(item);
          delete filteredArguments[key];
        }
        switch (item.type) {
          case CONSTANTS.itemType.DATA:
          case CONSTANTS.itemType.EVENT: {
            _processItem(item);
            break;
          }
          case CONSTANTS.itemType.FCTN: {
            delete filteredArguments[key];
            _processItem(item);
            break;
          }
          case CONSTANTS.itemType.LISTENER_ON:
          case CONSTANTS.itemType.LISTENER_OFF: {
            delete filteredArguments[key];
          }
        }
      });

      if (filteredArguments[0]) {
        return Array.prototype.push.apply(this, filteredArguments);
      }
    };

    /**
     * Returns a deep copy of the data layer state or of the object defined by the path.
     *
     * @param {Array|String} path The path of the property to get.
     * @returns {*} Returns a deep copy of the resolved value if a path is passed, a deep copy of the data layer state otherwise.
     */
    _dataLayer.getState = function(path) {
      if (path) {
        return get(cloneDeep(_state), path);
      }
      return cloneDeep(_state);
    };

    /**
     * Event listener callback.
     *
     * @callback eventListenerCallback A function that is called when the event of the specified type occurs.
     * @this {DataLayer}
     * @param {Object} event The event object pushed to the data layer that triggered the callback.
     * @param {Object} [beforeState] The state before the change caused by the event. Available only when the event
     * object has data that modify the state. If a path filter option has been provided when registering the event,
     * the object will only contain the data at the defined path.
     * @param {Object} [afterState] The state after the change caused by the event. Available only when the event
     * object has data that modify the state. If a path filter option has been provided when registering the event,
     * the object will only contain the data at the defined path.
     */

    /**
     * Sets up a function that will be called whenever the specified event is triggered.
     *
     * @param {String} type A case-sensitive string representing the event type to listen for.
     * @param {eventListenerCallback} callback A function that is called when the event of the specified type occurs.
     * @param {Object} [options] Optional characteristics of the event listener.
     * @param {String} [options.path] The path in the state object to filter the listening to.
     * @param {('past'|'future'|'all')} [options.scope] The timing to filter the listening to:
     *      - {String} past The listener is triggered for past events only.
     *      - {String} future The listener is triggered for future events only.
     *      - {String} all The listener is triggered for both past and future events (default value).
     */
    _dataLayer.addEventListener = function(type, callback, options) {
      const eventListenerItem = Item({
        on: type,
        handler: callback,
        scope: options && options.scope,
        path: options && options.path
      });

      _processItem(eventListenerItem);
    };

    /**
     * Removes an event listener previously registered with addEventListener().
     *
     * @param {String} type A case-sensitive string representing the event type to listen for.
     * @param {Function} [listener] Optional function that is to be removed.
     */
    _dataLayer.removeEventListener = function(type, listener) {
      const eventListenerItem = Item({
        off: type,
        handler: listener
      });

      _processItem(eventListenerItem);
    };
  };

  /**
   * Processes all items that already exist on the stack.
   *
   * @private
   */
  function _processItems() {
    for (let i = 0; i < _preLoadedItems.length; i++) {
      _dataLayer.push(_preLoadedItems[i]);
    }
  };

  /**
   * Processes an item pushed to the stack.
   *
   * @param {Item} item The item to process.
   * @private
   */
  function _processItem(item) {
    if (!item.valid) {
      _logInvalidItemError(item);
      return;
    }

    /**
     * Returns all items before the provided one.
     *
     * @param {Item} item The item.
     * @returns {Array<Item>} The items before.
     * @private
     */
    function _getBefore(item) {
      if (!(_dataLayer.length === 0 || item.index > _dataLayer.length - 1)) {
        return _dataLayer.slice(0, item.index).map(itemConfig => Item(itemConfig));
      }
      return [];
    }

    const typeProcessors = {
      data: function(item) {
        _updateState(item);
        _listenerManager.triggerListeners(item);
      },
      fctn: function(item) {
        item.config.call(_dataLayer, _dataLayer);
      },
      event: function(item) {
        if (item.data) {
          _updateState(item);
        }
        _listenerManager.triggerListeners(item);
      },
      listenerOn: function(item) {
        const listener = Listener(item);
        switch (listener.scope) {
          case CONSTANTS.listenerScope.PAST: {
            for (const registeredItem of _getBefore(item)) {
              _listenerManager.triggerListener(listener, registeredItem);
            }
            break;
          }
          case CONSTANTS.listenerScope.FUTURE: {
            _listenerManager.register(listener);
            break;
          }
          case CONSTANTS.listenerScope.ALL: {
            const registered = _listenerManager.register(listener);
            if (registered) {
              for (const registeredItem of _getBefore(item)) {
                _listenerManager.triggerListener(listener, registeredItem);
              }
            }
          }
        }
      },
      listenerOff: function(item) {
        _listenerManager.unregister(Listener(item));
      }
    };

    typeProcessors[item.type](item);
  };

  /**
   * Logs error for invalid item pushed to the data layer.
   *
   * @param {Item} item The invalid item.
   * @private
   */
  function _logInvalidItemError(item) {
    const message = 'The following item cannot be handled by the data layer ' +
      'because it does not have a valid format: ' +
      JSON.stringify(item.config);
    console.error(message);
  };

  return DataLayerManager;
};

},{"../custom-lodash":1,"../version.json":14,"./constants":2,"./item":5,"./listener":7,"./listenerManager":8,"./utils/customMerge":10}],4:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const DataLayerManager = require('./dataLayerManager');

/**
 * Data Layer.
 *
 * @type {Object}
 */
const DataLayer = {
  Manager: DataLayerManager
};

window.adobeDataLayer = window.adobeDataLayer || [];

DataLayer.Manager({
  dataLayer: window.adobeDataLayer
});

/**
 * @typedef  {Object} ListenerOnConfig
 * @property {String} on Name of the event to bind to.
 * @property {String} [path] Object key in the state to bind to.
 * @property {ListenerScope} [scope] Scope of the listener.
 * @property {Function} handler Handler to execute when the bound event is triggered.
 */

/**
 * @typedef  {Object} ListenerOffConfig
 * @property {String} off Name of the event to unbind.
 * @property {String} [path] Object key in the state to bind to.
 * @property {ListenerScope} [scope] Scope of the listener.
 * @property {Function} [handler] Handler for a previously attached event to unbind.
 */

/**
 * @typedef {Object} DataConfig
 * @property {Object} data Data to be updated in the state.
 */

/**
 * @typedef {Object} EventConfig
 * @property {String} event Name of the event.
 * @property {Object} [eventInfo] Additional information to pass to the event handler.
 * @property {DataConfig.data} [data] Data to be updated in the state.
 */

/**
 * @typedef {DataConfig | EventConfig | ListenerOnConfig | ListenerOffConfig} ItemConfig
 */

/**
 * Triggered when there is change in the data layer state.
 *
 * @event DataLayerEvent.CHANGE
 * @type {Object}
 * @property {Object} data Data pushed that caused a change in the data layer state.
 */

/**
 * Triggered when an event is pushed to the data layer.
 *
 * @event DataLayerEvent.EVENT
 * @type {Object}
 * @property {String} name Name of the committed event.
 * @property {Object} eventInfo Additional information passed with the committed event.
 * @property {Object} data Data that was pushed alongside the event.
 */

/**
 * Triggered when an arbitrary event is pushed to the data layer.
 *
 * @event <custom>
 * @type {Object}
 * @property {String} name Name of the committed event.
 * @property {Object} eventInfo Additional information passed with the committed event.
 * @property {Object} data Data that was pushed alongside the event.
 */

module.exports = DataLayer;

},{"./dataLayerManager":3}],5:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = require('../custom-lodash');
const isPlainObject = _.isPlainObject;
const isEmpty = _.isEmpty;
const omit = _.omit;

const dataMatchesContraints = require('./utils/dataMatchesContraints');
const ITEM_CONSTRAINTS = require('./itemConstraints');
const CONSTANTS = require('./constants');

/**
 * Constructs a data layer item.
 *
 * @param {ItemConfig} itemConfig The data layer item configuration.
 * @param {Number} index The item index in the array of existing items.
 */

module.exports = function(itemConfig, index) {
  const _config = itemConfig;
  const _index = index;
  const _type = getType();
  const _data = getData();
  const _valid = !!_type;

  function getType() {
    return Object.keys(ITEM_CONSTRAINTS).find(key => dataMatchesContraints(_config, ITEM_CONSTRAINTS[key])) ||
      (typeof _config === 'function' && CONSTANTS.itemType.FCTN) ||
      (isPlainObject(_config) && CONSTANTS.itemType.DATA);
  }

  function getData() {
    const data = omit(_config, Object.keys(ITEM_CONSTRAINTS.event));
    if (!isEmpty(data)) {
      return data;
    }
  }

  return {
    /**
     * Returns the item configuration.
     *
     * @returns {ItemConfig} The item configuration.
     */
    config: _config,

    /**
     * Returns the item type.
     *
     * @returns {itemType} The item type.
     */
    type: _type,

    /**
     * Returns the item data.
     *
     * @returns {DataConfig} The item data.
     */
    data: _data,

    /**
     * Indicates whether the item is valid.
     *
     * @returns {Boolean} true if the item is valid, false otherwise.
     */
    valid: _valid,

    /**
     * Returns the index of the item in the array of existing items (added with the standard Array.prototype.push())
     *
     * @returns {Number} The index of the item in the array of existing items if it exists, -1 otherwise.
     */
    index: _index
  };
};

},{"../custom-lodash":1,"./constants":2,"./itemConstraints":6,"./utils/dataMatchesContraints":11}],6:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

/**
 * Constraints for each type of the item configuration.
 */

const itemConstraints = {
  event: {
    event: {
      type: 'string'
    },
    eventInfo: {
      optional: true
    }
  },
  listenerOn: {
    on: {
      type: 'string'
    },
    handler: {
      type: 'function'
    },
    scope: {
      type: 'string',
      values: ['past', 'future', 'all'],
      optional: true
    },
    path: {
      type: 'string',
      optional: true
    }
  },
  listenerOff: {
    off: {
      type: 'string'
    },
    handler: {
      type: 'function',
      optional: true
    },
    scope: {
      type: 'string',
      values: ['past', 'future', 'all'],
      optional: true
    },
    path: {
      type: 'string',
      optional: true
    }
  }
};

module.exports = itemConstraints;

},{}],7:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const CONSTANTS = require('./constants');

/**
 * Constructs a data layer listener.
 *
 * @param {Item} item The item from which to construct the listener.
 */

module.exports = function(item) {
  const _event = item.config.on || item.config.off;
  const _handler = item.config.handler || null;
  const _scope = item.config.scope || (item.config.on && CONSTANTS.listenerScope.ALL) || null;
  const _path = item.config.path || null;

  return {
    /**
     * Returns the listener event name.
     *
     * @returns {String} The listener event name.
     */
    event: _event,

    /**
     * Returns the listener handler.
     *
     * @returns {(Function|null)} The listener handler.
     */
    handler: _handler,

    /**
     * Returns the listener scope.
     *
     * @returns {(String|null)} The listener scope.
     */
    scope: _scope,

    /**
     * Returns the listener path.
     *
     * @returns {(String|null)} The listener path.
     */
    path: _path
  };
};

},{"./constants":2}],8:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = require('../custom-lodash');
const cloneDeep = _.cloneDeep;
const get = _.get;

const constants = require('./constants');
const listenerMatch = require('./utils/listenerMatch');
const indexOfListener = require('./utils/indexOfListener');

/**
 * Creates a listener manager.
 *
 * @param {Manager} dataLayerManager The data layer manager.
 * @returns {ListenerManager} A listener manager.
 */
module.exports = function(dataLayerManager) {
  const _listeners = {};
  const _dataLayerManager = dataLayerManager;

  /**
   * Find index of listener in listeners object.
   */
  const _indexOfListener = indexOfListener.bind(null, _listeners);

  const ListenerManager = {
    /**
     * Registers a listener.
     *
     * @function
     * @param {Listener} listener The listener to register.
     * @returns {Boolean} true if the listener was registered, false otherwise.
     */
    register: function(listener) {
      const event = listener.event;

      if (Object.prototype.hasOwnProperty.call(_listeners, event)) {
        if (_indexOfListener(listener) === -1) {
          _listeners[listener.event].push(listener);
          return true;
        }
      } else {
        _listeners[listener.event] = [listener];
        return true;
      }
      return false;
    },
    /**
     * Unregisters a listener.
     *
     * @function
     * @param {Listener} listener The listener to unregister.
     */
    unregister: function(listener) {
      const event = listener.event;

      if (Object.prototype.hasOwnProperty.call(_listeners, event)) {
        if (!(listener.handler || listener.scope || listener.path)) {
          _listeners[event] = [];
        } else {
          const index = _indexOfListener(listener);
          if (index > -1) {
            _listeners[event].splice(index, 1);
          }
        }
      }
    },
    /**
     * Triggers listeners related to the passed item.
     *
     * @function
     * @param {Item} item Item to trigger listener for.
     */
    triggerListeners: function(item) {
      const triggeredEvents = _getTriggeredEvents(item);
      triggeredEvents.forEach(function(event) {
        if (Object.prototype.hasOwnProperty.call(_listeners, event)) {
          for (const listener of _listeners[event]) {
            _callHandler(listener, item, false);
          }
        }
      });
    },
    /**
     * Triggers a single listener on the passed item.
     *
     * @function
     * @param {Listener} listener Listener to call.
     * @param {Item} item Item to call the listener with.
     */
    triggerListener: function(listener, item) {
      _callHandler(listener, item, true);
    }
  };

  /**
   * Calls the listener handler on the item if a match is found.
   *
   * @param {Listener} listener The listener.
   * @param {Item} item The item.
   * @param {Boolean} isPastItem Flag indicating whether the item was registered before the listener.
   * @private
   */
  function _callHandler(listener, item, isPastItem) {
    if (listenerMatch(listener, item)) {
      const callbackArgs = [cloneDeep(item.config)];

      if (item.data) {
        if (listener.path) {
          const oldValue = get(_dataLayerManager.getPreviousState(), listener.path);
          const newValue = get(cloneDeep(item.data), listener.path);
          callbackArgs.push(oldValue, newValue);
        } else if (!isPastItem) {
          const oldState = _dataLayerManager.getPreviousState();
          const newState = cloneDeep(_dataLayerManager.getState());
          callbackArgs.push(oldState, newState);
        }
      }

      listener.handler.apply(_dataLayerManager.getDataLayer(), callbackArgs);
    }
  }

  /**
   * Returns the names of the events that are triggered for this item.
   *
   * @param {Item} item The item.
   * @returns {Array<String>} The names of the events that are triggered for this item.
   * @private
   */
  function _getTriggeredEvents(item) {
    const triggeredEvents = [];

    switch (item.type) {
      case constants.itemType.DATA: {
        triggeredEvents.push(constants.dataLayerEvent.CHANGE);
        break;
      }
      case constants.itemType.EVENT: {
        triggeredEvents.push(constants.dataLayerEvent.EVENT);
        if (item.data) triggeredEvents.push(constants.dataLayerEvent.CHANGE);
        if (item.config.event !== constants.dataLayerEvent.CHANGE) {
          triggeredEvents.push(item.config.event);
        }
        break;
      }
    }
    return triggeredEvents;
  }

  return ListenerManager;
};

},{"../custom-lodash":1,"./constants":2,"./utils/indexOfListener":12,"./utils/listenerMatch":13}],9:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = require('../../custom-lodash');
const has = _.has;
const get = _.get;

/**
  * Checks if the object contains an ancestor that is set to null or undefined.
  *
  * @param {Object} object The object to check.
  * @param {String} path The path to check.
  * @returns {Boolean} true if the object contains an ancestor that is set to null or undefined, false otherwise.
  * @private
  */
module.exports = function(object, path) {
  let ancestorPath = path.substring(0, path.lastIndexOf('.'));
  while (ancestorPath) {
    if (has(object, ancestorPath)) {
      const ancestorValue = get(object, ancestorPath);
      if (ancestorValue === null || ancestorValue === undefined) {
        return true;
      }
    }
    ancestorPath = ancestorPath.substring(0, ancestorPath.lastIndexOf('.'));
  }

  return false;
};

},{"../../custom-lodash":1}],10:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = require('../../custom-lodash');
const cloneDeepWith = _.cloneDeepWith;
const isObject = _.isObject;
const isArray = _.isArray;
const reject = _.reject;
const mergeWith = _.mergeWith;
const isNull = _.isNull;

/**
 * Merges the source into the object and sets objects as 'undefined' if they are 'undefined' in the source object.
 *
 * @param {Object} object The object into which to merge.
 * @param {Object} source The source to merge with.
 * @returns {Object} The object into which source was merged in.
 */
module.exports = function(object, source) {
  const makeOmittingCloneDeepCustomizer = function(predicate) {
    return function omittingCloneDeepCustomizer(value, key, object, stack) {
      if (isObject(value)) {
        if (isArray(value)) {
          return reject(value, predicate).map(item => cloneDeepWith(item, omittingCloneDeepCustomizer));
        }

        const clone = {};
        for (const subKey of Object.keys(value)) {
          if (!predicate(value[subKey])) {
            clone[subKey] = cloneDeepWith(value[subKey], omittingCloneDeepCustomizer);
          }
        }
        return clone;
      }
      return undefined;
    };
  };

  const customizer = function(objValue, srcValue, key, object) {
    if (typeof srcValue === 'undefined' || srcValue === null) {
      return null;
    }
  };

  const omitDeep = function(value, predicate = (val) => !val) {
    return cloneDeepWith(value, makeOmittingCloneDeepCustomizer(predicate));
  };

  mergeWith(object, source, customizer);

  // Remove null or undefined objects
  object = omitDeep(object, isNull);

  return object;
};

},{"../../custom-lodash":1}],11:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

module.exports = function(data, constraints) {
  // Go through all constraints and find one which does not match the data
  const foundObjection = Object.keys(constraints).find(key => {
    const type = constraints[key].type;
    const supportedValues = key && constraints[key].values;
    const mandatory = !constraints[key].optional;
    const value = data[key];
    const valueType = typeof value;
    const incorrectType = type && valueType !== type;
    const noMatchForSupportedValues = supportedValues && !supportedValues.includes(value);
    if (mandatory) {
      return !value || incorrectType || noMatchForSupportedValues;
    } else {
      return value && (incorrectType || noMatchForSupportedValues);
    }
  });
  // If no objections found then data matches contraints
  return typeof foundObjection === 'undefined';
};

},{}],12:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = require('../../custom-lodash');
const isEqual = _.isEqual;

module.exports = function(listeners, listener) {
  const event = listener.event;

  if (Object.prototype.hasOwnProperty.call(listeners, event)) {
    for (const [index, registeredListener] of listeners[event].entries()) {
      if (isEqual(registeredListener.handler, listener.handler)) {
        return index;
      }
    }
  }
  return -1;
};

},{"../../custom-lodash":1}],13:[function(require,module,exports){
/*
Copyright 2020 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

const _ = require('../../custom-lodash');
const has = _.has;

const CONSTANTS = require('../constants');
const ancestorRemoved = require('./ancestorRemoved');

/**
 * Checks if the listener matches the item.
 *
 * @param {Listener} listener The listener.
 * @param {Item} item The item.
 * @returns {Boolean} true if listener matches the item, false otherwise.
 */
function listenerMatch(listener, item) {
  const event = listener.event;
  const itemConfig = item.config;
  let matches = false;

  if (item.type === CONSTANTS.itemType.DATA) {
    if (event === CONSTANTS.dataLayerEvent.CHANGE) {
      matches = selectorMatches(listener, item);
    }
  } else if (item.type === CONSTANTS.itemType.EVENT) {
    if (event === CONSTANTS.dataLayerEvent.EVENT || event === itemConfig.event) {
      matches = selectorMatches(listener, item);
    }
    if (item.data && event === CONSTANTS.dataLayerEvent.CHANGE) {
      matches = selectorMatches(listener, item);
    }
  }

  return matches;
}

/**
 * Checks if a listener has a selector that points to an object in the data payload of an item.
 *
 * @param {Listener} listener The listener to extract the selector from.
 * @param {Item} item The item.
 * @returns {Boolean} true if a selector is not provided or if the given selector is matching, false otherwise.
 * @private
 */
function selectorMatches(listener, item) {
  if (item.data && listener.path) {
    return has(item.data, listener.path) || ancestorRemoved(item.data, listener.path);
  }

  return true;
}

module.exports = listenerMatch;

},{"../../custom-lodash":1,"../constants":2,"./ancestorRemoved":9}],14:[function(require,module,exports){
module.exports={
  "version": "1.1.1"
}

},{}]},{},[4])

